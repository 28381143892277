<template>
  <van-nav-bar left-arrow @click-left="back">
    <template #title>
      <span class="font-semibold">得物</span>
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.van-nav-bar /deep/ .van-icon {
  color: black;
}
.van-nav-bar {
  color: black;
  background-color: rgb(248, 248, 248);
}
</style>