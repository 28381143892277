import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token'),
    logo: null,
  },

  getters: {
  },
  mutations: {
    setLogo(state, logo) {
      state.logo = logo
    },
    setToken(state, token) {
      sessionStorage.setItem("token", token);
      state.token = token
    },
  },
  actions: {
  },
  modules: {
  }
})

function getObjFromLocalStore(key, defaultValue) {
  let str = localStorage.getItem(key)
  return str ? JSON.parse(str) : defaultValue
}

function setObjToLocalStore(key, val) {
  let str = JSON.stringify(val)
  localStorage.setItem(key, str)
}


