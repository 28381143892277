import Vue from 'vue'
import VueRouter from 'vue-router'
// import baseLayout from '../components/layout/base.vue'
// import plainLayout from '../components/layout/plain.vue'
// import authLayout from '../components/layout/auth.vue'
import HomeView from '../views/home/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/forms/:code',
    component: () => import(/* webpackChunkName: "form" */ '../views/form/index.vue')
  },
  {
    path: '/results/:id',
    component: () => import(/* webpackChunkName: "form" */ '../views/result/index.vue')
  },
  {
    path: '/convert/:id',
    component: () => import(/* webpackChunkName: "form" */ '../views/result/convert.vue')
  },
  {
    path: '/myreport',
    component: () => import(/* webpackChunkName: "form" */ '../views/myreport/index.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  document.getElementById('app').scrollTop = 0
  next()
});


export default router
