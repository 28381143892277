<template>
  <div
    id="app"
    class="min-h-full w-screen text-black bg-white overflow-y-scroll"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
};
</script>

<style>


#app {
  /* color: rgb(31, 51, 73); */
  /* font-family: Helvetica, Arial, Tahoma, sans-serif; */
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
